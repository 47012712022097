$(document).on('ready', function () {
  $(document).on('ajaxStop', function (event) {
    var defaulttext = $('#contact-form-object').find(":selected").text(); 

    $('.select-default-text').text(defaulttext);

    $('#contact-form-object').on('change',function(){
       var newText = $('#contact-form-object').find(":selected").text(); 
        $('.select-default-text').text(newText);
    });

    var formWrapper = $('body > footer > .footer-wrapper .footer-contact-form');
    var gdprLink = formWrapper.data('gdpr-link');

    formWrapper.find('.gdpr-appendix > a').attr('href', gdprLink);
  });

  if (document.body.classList.contains('neos-backend')) {
    return document.addEventListener('Neos.PageLoaded', initAjaxForm);
  }

  initAjaxForm();
});

window._canInitAjaxForm = true;

window.initButtonClickHandler = function () {
  $('.ajax-form button[type="submit"]').on('click', function () {
    $('.ajax-form button[type="submit"]').removeAttr('clicked');
    $(this).attr('clicked', 'true');
  });
}

window._recaptchaloadthrottle = 0;
window.initAjaxForm = function () {
  if (typeof grecaptcha !== "object" && window._recaptchaloadthrottle <= 20) {
    window._recaptchaloadthrottle++;
    setTimeout(initAjaxForm, 300);
  } else if (window._recaptchaloadthrottle > 20) {
    throw new Error('ReCaptcha could not be loaded!');
  }

  $('.ajax-form').each(function (idx, ajaxForm) {
    var formIdentifier = $(ajaxForm).attr('data-identifier');
    var presetName = $(ajaxForm).attr('data-preset-name');
    var dimension = $(ajaxForm).attr('data-dimension');

    var formAjaxUrl = location.protocol + '//' + location.host + '/form/' + (dimension != '' ? dimension + '/' : 'en/') + presetName + '/' + formIdentifier;

    $(ajaxForm).off('submit').on('submit', 'form', function (e) {
      var formObj = $(this);
      var formURL = formObj.attr('action');
      var formData = new FormData(this);

      var trigger = $(this).find('button[clicked="true"]');
      formData.append($(trigger).attr('name'), $(trigger).attr('value'));

      $.ajax({
        url: formURL,
        type: 'POST',
        data: formData,
        mimeType: 'multipart/form-data',
        contentType: false,
        cache: false,
        processData: false,
        beforeSend: function () {
          $(ajaxForm).find('.ajax-content').replaceWith('<i class=" ajax-content fa fa-spinner fa-pulse"></i>');
        },
        success: function (data) {
          $(ajaxForm).find('.ajax-content').replaceWith(data);
          initButtonClickHandler();

          if ($(ajaxForm).find('.g-recaptcha').length) {
            var captcha = $(ajaxForm).find('.g-recaptcha');
            var sitekey = captcha.data('sitekey');
            grecaptcha.render(captcha[0], { "sitekey": sitekey });
          }
        }
      });

      e.preventDefault();
    });

    $(ajaxForm).load(formAjaxUrl + ' .ajax-content', function () {
      initButtonClickHandler();
      $(this).css("min-height", $(this).css("height"));
    });
  });
}

document.addEventListener('Neos.PageLoaded', function(event) {
  initAjaxForm();
});