window._gmapsloadthrottle = 0;
function initGoogleMaps() {
  if (typeof google !== "object" && window._gmapsloadthrottle <= 20) {
    window._gmapsloadthrottle++;
    return setTimeout(initGoogleMaps, 300);
  } else if (window._gmapsloadthrottle > 20) {
    throw new Error('Google Maps could not be loaded!');
  }

  window.maps = window.maps || [];

  $('.google-map-canvas').each(function (i, e, a) {

    $(this).attr('data-map-index', i);

    var id = $(this).data('map-id');

    var markers = JSON.parse($(this).data('markers').replace(new RegExp('\'', 'g'), '"').replace(new RegExp('\n', 'g'), ''));

    var mapMarkers = new Array();

    var mapConf = {
      center: { lat: 0, lng: 0 },
      zoom: (markers.markers.length > 1 ? 13 : 17),
      mapTypeId: google.maps.MapTypeId[$(this).data('type')],
      draggable: $(this).data('draggable'),
      // scrollwheel: $(this).data('scrollwheel'),
      scrollwheel: false,
      mapTypeControl: $(this).data('map-type-control'),
      zoomControl: $(this).data('zoom-control'),
      streetViewControl: $(this).data('street-view-control'),
      clickableIcons: !($('body').hasClass('partners') || $('body').hasClass('partner'))
    };
    var map = new google.maps.Map($(this).get(0), mapConf);

    var infowindow = new google.maps.InfoWindow({
      maxWidth: ($('body').hasClass('partners') ? 200 : 250)
    });

    window.maps[i] = {
      id: id,
      markers: [],
      mapObject: map,
      conf: mapConf
    }
    saveObj = window.maps[i];

    $.each(markers.markers, function (index, value) {

      for (var key in markers.markers[index]) {

        geocoder = new google.maps.Geocoder();

        geocoder.geocode({ 'address': markers.markers[index][key][0].street + ', ' + markers.markers[index][key][0].zip + ', ' + markers.markers[index][key][0].city + ', ' + markers.markers[index][key][0].country }, function (results, status) {

          if (status == google.maps.GeocoderStatus.OK) {

            if (markers.markers[index][key][0].icon == 'partner') {
              var conf = {
                map: map,
                icon: {
                  url: window.yellowMapMarker,
                  scaledSize: new google.maps.Size(30, 30)
                },
                position: results[0].geometry.location
              }
            } else if (markers.markers[index][key][0].icon == 'member') {
              var conf = {
                map: map,
                icon: {
                  url: window.greenMapMarker,
                  scaledSize: new google.maps.Size(30, 30)
                },
                position: results[0].geometry.location
              }
            } else {
              var conf = {
                map: map,
                icon: {
                  url: window.blueMapMarker,
                  scaledSize: new google.maps.Size(30, 30)
                },
                position: results[0].geometry.location
              }
            }

            var marker = new google.maps.Marker(conf);
            var markerIndex = mapMarkers.length;

            mapMarkers.push(marker);

            saveObj.markers.push({
              detail: markers.markers[index][key][0],
              marker: marker,
              opacity: 1
            })

            var detail = markers.markers[index][key][0];
            if (detail.title != '' || detail.text != '') {
              google.maps.event.addListener(marker, 'click', function () {
                var content = '<div id="inner-map-info-window-content">';

                if (detail.logo != '') {
                  content += '<img class="inner-map-detail-logo" src="' + detail.logo + '" alt="' + detail.title + '" title="' + detail.title + '" />';
                }

                content += '<h5>' + detail.title + '</h5>';

                if (detail.text != '') {
                  content += '<p>' + detail.text + '</p>';
                }

                content += '</div>';

                infowindow.setContent(content);
                infowindow.open(map, this);

                $('#inner-map-info-window-content').parent().add($('#inner-map-info-window-content').parent().parent()).css('overflow', 'visible');
              });
            }
          }

          if ($('.google-map-canvas[data-map-id=' + id + ']').data('fit-bounds') == 1) {
            var bounds = new google.maps.LatLngBounds();

            for (var i = 0; i < mapMarkers.length; i++) {
              bounds.extend(mapMarkers[i].getPosition());
            }

            if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
              var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
              var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
              bounds.extend(extendPoint1);
              bounds.extend(extendPoint2);
            }

            map.fitBounds(bounds);

            if ($('body').hasClass('partner')) {
              map.setZoom(15);
            }
          }
        });
      }
    });

    if ($(this).data('fit-bounds') == 0) {
      map.setCenter({ lat: $(this).data('lat'), lng: $(this).data('lng') });
      map.setZoom($(this).data('zoom'));
    } else {
      var worker;

      $(window).on('resize', function () {
        if (worker) worker = clearTimeout(worker);

        worker = setTimeout(function () {
          var bounds = new google.maps.LatLngBounds();

          for (var i = 0; i < mapMarkers.length; i++) {
            bounds.extend(mapMarkers[i].getPosition());
          }

          if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
            var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
            bounds.extend(extendPoint1);
            bounds.extend(extendPoint2);
          }

          map.fitBounds(bounds);

          if ($('body').hasClass('partner')) {
            map.setZoom(15);
          }

          worker = undefined;
        }, 300);
      })
    }

    if (map.getTilt()) {
      map.setTilt($(this).data('tilt'));
    }
  });
}

if (typeof document.addEventListener === 'function') {
  document.addEventListener('Neos.PageLoaded', function (event) {
    initGoogleMaps();
  });

  document.addEventListener('Neos.NodeCreated', function (event) {
    if ($(event.detail.element).find('.google-map-canvas')) {
      initGoogleMaps();
    }
  });
}

$(function() {
  initGoogleMaps();
});